import Vue from "vue";
import {ATOM, fATOM, wATOM,INFI,getConfig} from "@/config/network";
import {SigningCosmWasmClient} from "@cosmjs/cosmwasm-stargate";
import {GasPrice, SigningStargateClient} from "@cosmjs/stargate";
import utc from "dayjs/plugin/utc";
import  bech32 from "bech32";
import {coins} from "@cosmjs/proto-signing";
import Decimal from 'decimal.js';
const dayjs = require('dayjs');
dayjs.extend(utc)
const vm = new Vue();

function dealErr(err,context){
  if (err.message.indexOf('Request rejected')!==-1) {
    context.$msg.error(context.$t("web3.ACTION_REJECTED"))
  } else if(err.message.indexOf("Generic error: The unstake is claimed once in a day"!==-1)){
    context.$msg.error(context.$t("web3.CLAIM_ETH_LIMIT"))
  }else if(err.message.indexOf("does not exist on chain, Send some tokens there before trying to query sequence"!==-1)){
    context.$msg.error(context.$t("web3.GAS_Insufficient"))
  }else if(err.message.indexOf("Insufficient quota of this account"!==-1)){
    context.$msg.error(context.$t("web3.UNPREDICTABLE_GAS_LIMIT"))
  }else {
    context.$msg.error(context.$t("web3.CALL_EXCEPTION"))
  }
}

export const Signer  = async (config= getConfig.juno) => {
  if (window.keplr !== undefined) {
    return await window.keplr.getOfflineSigner(config.chainId);
  } else {
    console.log('error==>')
    vm.$msg.error("ERROR")
  }
}
export const getAccount =async (config = getConfig.juno) =>{
  let offlineSigner =  await Signer(config);
  const accounts = await offlineSigner.getAccounts();
  if(accounts){
    //console.log(accounts)
    return accounts[0].address
  }else{
    return null
  }

}
export const getBalance =async (account,contractAddress) => {
  if(!contractAddress){
    //判断地址开头，若是juno则返回juno余额，若是celestia则返回TIA余额
    let signer
    if(account.toLowerCase().startsWith("juno1")){
      signer = await Signer(getConfig.juno);
      const signingClient = await SigningStargateClient.connectWithSigner(getConfig.juno.rpcUrl, signer,{
        gasPrice: GasPrice.fromString('0.015utia')
      })
      let balance = await  signingClient.getAllBalances(account)
      if(balance!=null && balance.length>0){
        return balance[0]
      }else{
        return {
          "denom": "ujuno",
          "amount": "0"
        }
      }

    }else if (account.toLowerCase().startsWith("celestia")){
      signer = await Signer(getConfig.celestia);
      const signingClient = await SigningStargateClient.connectWithSigner(getConfig.celestia.rpcUrl, signer,{
        gasPrice: GasPrice.fromString('0.015utia')
      })
      let balance = await  signingClient.getAllBalances(account)
      if(balance!=null && balance.length>0){
        return balance[0]
      }else{
        return {
          "denom": "utia",
          "amount": "0"
        }
      }
    }
  }
  let client =  await  CosmClient()
  let params =  {
    balance: { "address": account},
  }

  if(contractAddress.toLowerCase().startsWith("ibc")){
    return   await client.getBalance(account, contractAddress)
  }else {
    let balance = await client.queryContractSmart(
      contractAddress,
      params
    );
    return balance;
  }
}
//  FTAOM ==> Staked fAtom
export const getStakeAmount =async (account,configStake) => {
  let client =  await  CosmClient()
  let balance = await client.queryContractSmart(
    configStake,
    {
      user_stake_amount: {"address": account},

    },
  );
  return balance
}
// FTAOM ==> Pending Reward
export const getPendingReward =async (account,configStake) => {
  let client =  await  CosmClient()
  let balance = await client.queryContractSmart(
    configStake,
    {
      user_reward_claimable: {"address": account},
    }
  );
  return balance
}

//

export const getUserClaimableTIA =async (account) => {

  let client =  await  CosmClient()
  let result = await client.queryContractSmart(
    getConfig.celestia.mintAddr,
    {
      user_claimable: {"address": account},
    }
  );
  let claimAble = result.claimable.claimable_amount
  //console.log(`getUserClaimableAtom: ${claimAble}`,claimAble)
  return claimAble

}
export const getUserClaimableAtom =async (account) => {

  let client =  await  CosmClient()
  let result = await client.queryContractSmart(
    getConfig.juno.mintAddr,
    {
      user_claimable: {"address": account},
    }
  );
  let claimAble = result.claimable.claimable_amount
  //console.log(`getUserClaimableAtom: ${claimAble}`,claimAble)
  return claimAble

}

export const getUserClaimableETH =async (account) => {

  let client =  await  CosmClient()
  let result = await client.queryContractSmart(
    getConfig.juno.mintETHAddr,
    {
      user_claimable: {"address": account},
    }
  );
  let claimAble = result.claimable.claimable_amount
  //console.log(`getUserClaimableAtom: ${claimAble}`,claimAble)
  return claimAble

}
export const getUserClaimableSOL =async (account) => {

  let client =  await  CosmClient()
  let result = await client.queryContractSmart(
    getConfig.juno.mintSOLAddr,
    {
      user_claimable: {"address": account},
    }
  );
  let claimAble = result.claimable.claimable_amount
  //console.log(`getUserClaimableAtom: ${claimAble}`,claimAble)
  return claimAble

}
export const getUserUnbonding =async (account,contractAddress) => {

  let client =  await  CosmClient()
  let result = await client.queryContractSmart(
    contractAddress,
    {
      user_unbond_amount: {"address": account},
    }
  );
  let unbond_amount = result.unbond_amount.unbond_amount
  //console.log(`getUserUnbonding: ${unbond_amount}`,unbond_amount)
  return unbond_amount

}

export const getINFIUserUnbonding =async (account,contractAddress) => {

  let client =  await  CosmClient()
  let result = await client.queryContractSmart(
    contractAddress,
    {
      user_unbond_amount: {"address": account},
    }
  );
  //console.log(`getUserUnbonding: result`,result)
  ////console.log(result.unbond_amount)
  let unbond_amount = result.unbond_amount.unbond_amount

  return unbond_amount

}

export const getUserUnstakeRecordsAtom = async (account) => {

  let client =  await  CosmClient()
  let result = await client.queryContractSmart(
    getConfig.juno.mintAddr,
    {
      user_unstake_records: {"address": account},
    }
  );
  let unstakeRecords = result.unstake_records.records
  //console.log(`getUserUnstakeRecords: ${unstakeRecords}`,unstakeRecords)
  const utcNow = dayjs().utc()
  for (let i = 0; i < unstakeRecords.length; i++) {
    let item = unstakeRecords[i]
    if (item.is_claimed == false && utcNow.isBefore(dayjs(Number(item.update_time) / 1e6).add(getConfig.juno.atomMintLock.num, getConfig.juno.atomMintLock.unit))) {
      return item
    }
  }
  return null
}
export const getUserUnstakeRecordsTia = async (account) => {

  let client =  await  CosmClient()
  let result = await client.queryContractSmart(
    getConfig.celestia.mintAddr,
    {
      user_unstake_records: {"address": account},
    }
  );
  let unstakeRecords = result.unstake_records.records
  //console.log(`getUserUnstakeRecords: ${unstakeRecords}`,unstakeRecords)
  const utcNow = dayjs().utc()
  for (let i = 0; i < unstakeRecords.length; i++) {
    let item = unstakeRecords[i]
    if (item.is_claimed == false && utcNow.isBefore(dayjs(Number(item.update_time) / 1e6).add(getConfig.juno.tiaMintLock.num, getConfig.juno.tiaMintLock.unit))) {
      return item
    }
  }
  return null
}

// eth
export const getUserUnstakeRecordsEth = async (account) => {

  let client =  await  CosmClient()
  let result = await client.queryContractSmart(
    getConfig.juno.mintETHAddr,
    {
      user_unstake_records: {"address": account},
    }
  );
  let unstakeRecords = result.unstake_records.records
  //console.log(`getUserUnstakeRecords: ${unstakeRecords}`,unstakeRecords)
  const utcNow = dayjs().utc()
  for (let i = 0; i < unstakeRecords.length; i++) {
    let item = unstakeRecords[i]
    if (item.is_claimed == false && utcNow.isBefore(dayjs(Number(item.update_time) / 1e6).add(getConfig.juno.ethMintLock.num, getConfig.juno.ethMintLock.unit))) {
      return item
    }
  }
  return null
}
// sol
export const getUserUnstakeRecordsSol = async (account) => {

  let client =  await  CosmClient()
  let result = await client.queryContractSmart(
    getConfig.juno.mintSOLAddr,
    {
      user_unstake_records: {"address": account},
    }
  );
  let unstakeRecords = result.unstake_records.records
  //console.log(`getUserUnstakeRecords: ${unstakeRecords}`,unstakeRecords)
  const utcNow = dayjs().utc()
  for (let i = 0; i < unstakeRecords.length; i++) {
    let item = unstakeRecords[i]
    if (item.is_claimed == false && utcNow.isBefore(dayjs(Number(item.update_time) / 1e6).add(getConfig.juno.solMintLock.num, getConfig.juno.solMintLock.unit))) {
      return item
    }
  }
  return null
}

export const CosmClient =async (config = getConfig.juno) => {
  const options = {
    prefix: config.addressPrefix,
    gasPrice: GasPrice.fromString(config.gasPrice),
  };
  let signer = await  Signer(config);
  return SigningCosmWasmClient.connectWithSigner(config.rpcUrl,signer , options)
}


export  const  sendTokens = async (context,config,recipientAddress,amount,denom, memo="",callBack) =>{
  context.$showLoading("Loading");

  const client = await CosmClient(config)

  try {

    let account = await getAccount(config)
    // 准备转账信息
    const transferAmount = coins(amount , denom) // 1 TIA = 1,000,000 utia
    let  result =await client.sendTokens(
      account,
      recipientAddress,
      transferAmount,
      "auto",
      memo);

    for (let i = 0; i < 200; i++) {
      const txId = result.transactionHash
      let tx= await client.getTx(txId);
      ////console.log("receipt")
      ////console.log( tx)

      if (tx && +tx.code == 0) {
        context.$hideLoading();
        context.$msg.success(context.$t("exchange.exchangeSucc"), 5000,{
          type:'link',
          text:context.$t('global.ViewOnBlockchain'),
          link:config.scanUrl + `tx/${tx.hash}`,
          show:true
        })
        callBack && callBack()
        return
      } else if (tx && +tx.code != 0) {
        //console.log("receipt" + tx)
        context.$msg.error(tx.rawLog)
        return
      }
      context.isDebounce = false;
      context.$sleep(2000)
    }

    context.$hideLoading();
    context.$msg.error(this.$t("global.Contracttimeout"))

  } catch (err) {
    context.$hideLoading();
    console.log(err, 'error===>')
    console.log("错误信息:", err.message);
    dealErr(err,context)

  }
}

//user_stake_amount: ATOM总质押量，含锁定期内的ATOM
//user_unstakable: 实际质押、且可以unstake的ATOM数，不包含unstake锁定期内的ATOM数
//user_stake_amount-user_unstakable

export  const  exchange = async (context,config,msg,funds, memo="",callBack) =>{
  context.$showLoading("Loading");

  const client = await CosmClient(config)

  try {


    let account = await getAccount(config)
    const mintAddr = config.mintAddr;
    let  result =await client.execute(
      account,
      mintAddr,
      msg,
      "auto",
      memo,
      funds
    );

    for (let i = 0; i < 200; i++) {
      const txId = result.transactionHash
      let tx= await client.getTx(txId);
      if (tx && +tx.code == 0) {
        context.$hideLoading();
        context.$msg.success(context.$t("exchange.exchangeSucc"), 5000,{
          type:'link',
          text:context.$t('global.ViewOnBlockchain'),
          link:config.scanUrl + `tx/${tx.hash}`,
          show:true
        })
        callBack && callBack()
        return
      } else if (tx && +tx.code != 0) {
        context.$msg.error(tx.rawLog)
        return
      }
      context.isDebounce = false;
      context.$sleep(2000)
    }

    context.$hideLoading();
    context.$msg.error(context.$t("global.Contracttimeout"))

  } catch (err) {
    context.$hideLoading();
    console.log(err, 'error===>')
    console.log("错误信息:", err.message);
    dealErr(err,context)

  }
}

export  const  redeem = async (context,token,sendMsg,memo,callBack) =>{
  context.$showLoading("Loading");
  const client = await CosmClient()

  try {


    let account = await getAccount()

    let  result =await client.execute(
      account,
      token,
      sendMsg,
      "auto",
      memo
    );
    for (let i = 0; i < 200; i++) {
      const txId = result.transactionHash
      let tx= await client.getTx(txId);
      //console.log("receipt")
      //console.log( tx)
      if (tx && +tx.code == 0) {
        context.$hideLoading();
        context.$msg.success(context.$t('exchange.redeemSucc'), 5000,{
          type:'link',
          text:context.$t('global.ViewOnBlockchain'),
          link: getConfig.juno.scanUrl + `tx/${tx.hash}`,
          show:true
    })
        callBack()
        return
      } else if (tx && +tx.code != 0) {
        //console.log("receipt" + tx)
        context.$msg.error(tx.rawLog)
        return
      }
      context.isDebounce = false;
      context.$sleep(2000)
    }

    context.$hideLoading();
    context.$msg.error(context.$t("global.Contracttimeout"))

  } catch (err) {
    context.$hideLoading();
    console.log(err, 'error===>')
    console.log("错误信息:", err.message);
    dealErr(err,context)

  }


}

export const mainTokenClaim = async (context,config,callBack) => {

  context.$showLoading("Loading");
  const client = await CosmClient()
  try{
    let account = await getAccount()
    const sendMsg = {"claim_all_unstake": {}}
    let result = await client.execute(
      account,
      config.mintAddr,
      sendMsg,
      "auto",
      "",
    );

    for (let i = 0; i < 200; i++) {
      //console.log(result,'result')
      const txId = result.transactionHash
      let tx= await client.getTx(txId);
      //console.log("receipt")
      //console.log( tx)

      if (tx && +tx.code == 0) {
        context.$hideLoading();
        context.$msg.success(context.$t("exchaneg.unlockedfund"), 5000,{
          type:'link',
          text:context.$t('global.ViewOnBlockchain'),
          link: getConfig.juno.scanUrl +`tx/${tx.hash}`,
          show:true
    })
        callBack()
        return
      } else if (tx && +tx.code != 0) {
        //console.log("receipt" + tx)
        context.$msg.error(tx.rawLog)
        return
      }
      context.isDebounce = false;
      context.$sleep(2000)
    }

    context.$hideLoading();
    context.$msg.error(context.$t("global.Contracttimeout"))
  }catch(err){
    context.$hideLoading();
    console.log(err, 'error===>')
    console.log(err, 'error===>')
    console.log("错误信息:", err.message);
    dealErr(err,context)

  }
}

export const mainCommonTokenClaim = async (context,mintAddr,callBack) => {

  context.$showLoading("Loading");
  const client = await CosmClient()
  try{
    let account = await getAccount()
    const sendMsg = {"claim_all_unstake": {}}
    let result = await client.execute(
      account,
      mintAddr,
      sendMsg,
      "auto",
      "",
    );

    for (let i = 0; i < 200; i++) {
      //console.log(result,'result')
      const txId = result.transactionHash
      let tx= await client.getTx(txId);
      //console.log("receipt")
      //console.log( tx)

      if (tx && +tx.code == 0) {
        context.$hideLoading();
        context.$msg.success(context.$t("exchange.unlockedfund"), 5000,{
          type:'link',
          text:context.$t('global.ViewOnBlockchain'),
          link: getConfig.juno.scanUrl +`tx/${tx.hash}`,
          show:true
    })
        callBack()
        return
      } else if (tx && +tx.code != 0) {
        //console.log("receipt" + tx)
        context.$msg.error(tx.rawLog)
        return
      }
      context.isDebounce = false;
      context.$sleep(2000)
    }

    context.$hideLoading();
    context.$msg.error(context.$t("global.Contracttimeout"))
  }catch(err){
    context.$hideLoading();
    console.log(err, 'error===>')
    console.log(err, 'error===>')
    console.log("错误信息:", err.message);


    dealErr(err,context)
  }
}



//  授权Fatom金额
export const approveFatom  = async (context,fATOMORFTIA,getConfigStake) => {
  context.$showLoading("Loading");
  console.log(getConfigStake,'getConfigStake==>')
  const client = await CosmClient()
  try {

    let account = await getAccount()
    const sendMsg = {
        "increase_allowance": {
            "amount": "999999999999999999999999",
            "spender":getConfigStake,// getConfig.juno.fatomStake,
        }
    };

    let  result = await client.execute(
        account,
        fATOMORFTIA,// fATOM,
        sendMsg,
        "auto",
        "",
    );
    //console.log(result);
    for (let i = 0; i < 200; i++) {
      const txId = result.transactionHash
      let tx= await client.getTx(txId);
      //console.log("receipt")
      //console.log( tx)

      if (tx && +tx.code == 0) {
        context.$hideLoading();
        context.$msg.success(context.$t("exchange.ApproveSuccess"), 3000)
        return
      } else if (tx && +tx.code != 0) {
        //console.log("receipt" + tx)
        context.$msg.error(tx.rawLog)
        return
      }
      context.isDebounce = false;
      context.$sleep(2000)
    }

    context.$hideLoading();
    context.$msg.error(context.$t("global.Contracttimeout"))

} catch (err) {
  context.$hideLoading();
  console.log(err, 'error===>')
  console.log("错误信息:", err.message);
  dealErr(err,context)

  }
}

// 质押fAtom
export const stakeFatom = async (context,amount,fATOMORFTIA,getConfigStake,sucMsg,callback) => {
  context.$showLoading("Loading");

  const client = await CosmClient()
  const amountResult = new Decimal(amount).times(new Decimal('1e6'));
  console.log('Output:质押合约amount',amountResult.toString()); // O
  try{
    let account = await getAccount()
    const sendMsg = {
      "stake": {
          "amount": amountResult,
          "contract": fATOMORFTIA,//fATOM,
          "msg": ""
      }
  };

  let result = await client.execute(
      account,
      getConfigStake,// getConfig.juno.fatomStake,
      sendMsg,
      "auto",
      "",
  );

  //console.log(result,'stakeFatom===>');
  for (let i = 0; i < 200; i++) {
    const txId = result.transactionHash
    let tx= await client.getTx(txId);
    //console.log("receipt")
    //console.log( tx)

    if (tx && +tx.code == 0) {
      context.$hideLoading();
      context.$msg.success(sucMsg, 5000,{
            type:'link',
            text:context.$t('global.ViewOnBlockchain'),
            link:`https://www.mintscan.io/juno/tx/${tx.hash}`,
            show:true
      })
      callback && callback()
      return
    } else if (tx && +tx.code != 0) {
      //console.log("receipt" + tx)
      context.$msg.error(tx.rawLog)
      return
    }
    context.isDebounce = false;
    context.$sleep(2000)
  }

  context.$hideLoading();
  context.$msg.error(context.$t("global.Contracttimeout"))

  }catch(err){
    context.$hideLoading();
    console.log(err, 'error===>')
    console.log("错误信息:", err.message);
    dealErr(err,context)

  }
}

// 解押watom

export const unstakeWatom = async (context,amount,wATOMORwTIA,getConfigStake,sucMsg,callback) => {
  context.$showLoading("Loading");
  console.log(getConfigStake,'getConfigStake==>',wATOMORwTIA)
  const client = await CosmClient()
  const amountResult = new Decimal(amount).times(new Decimal('1e6'));
  console.log('Output:解押合约amount',amountResult.toString()); // O
  try{
    let account = await getAccount()
    const sendMsg = {
      "send": {
          "amount": amountResult,
          "contract": getConfigStake,//getConfig.juno.fatomStake,
          "msg": ""
      }
  };
  //console.log(sendMsg,'sendMsg');
  let result = await client.execute(
      account,
      wATOMORwTIA,//wATOM,
      sendMsg,
      "auto",
      "",
  );

  //console.log(result,'stakeFatom===>');
  for (let i = 0; i < 200; i++) {
    const txId = result.transactionHash
    let tx= await client.getTx(txId);
    //console.log("receipt")
    //console.log( tx)

    if (tx && +tx.code == 0) {
      context.$hideLoading();
      context.$msg.success(sucMsg, 5000,{
            type:'link',
            text:context.$t('global.ViewOnBlockchain'),
            link:`https://www.mintscan.io/juno/tx/${tx.hash}`,
            show:true
      })
      callback && callback()
      return
    } else if (tx && +tx.code != 0) {
      //console.log("receipt" + tx)
      context.$msg.error(tx.rawLog)
      return
    }
    context.isDebounce = false;
    context.$sleep(2000)
  }

  context.$hideLoading();
  context.$msg.error(context.$t("global.Contracttimeout"))

  }catch(err){
    context.$hideLoading();
    console.log(err, 'error===>')
    console.log("错误信息:", err.message);
    dealErr(err,context)

  }
}

// fatom claim
export const fatomInfiClaim = async (context,amount,configStake,callback,msg='claim INFI Success') => {
  context.$showLoading("Loading");
  const client = await CosmClient()
  console.log(amount,'fatomInfiClaim===amount')
  const amountResult = new Decimal(amount).times(new Decimal('1e6'));
  console.log('Output:合约amount',amountResult.toString()); // Output: 129126697

  try{
    let account = await getAccount()
    const sendMsg = {"claim_reward": {
       "amount": amountResult,

    }}
    let result = await client.execute(

      account,
      configStake,
      sendMsg,
      "auto",
      "",
    );

    for (let i = 0; i < 200; i++) {
      //console.log(result,'result')
      const txId = result.transactionHash
      let tx= await client.getTx(txId);
      //console.log("receipt")
      //console.log( tx)

      if (tx && +tx.code == 0) {
        context.$hideLoading();
        context.$msg.success(msg, 5000,{
          type:'link',
          text:context.$t('global.ViewOnBlockchain'),
          link:`https://www.mintscan.io/juno/tx/${tx.hash}`,
          show:true
         })
         callback && callback()
        return
      } else if (tx && +tx.code != 0) {
        //console.log("receipt" + tx)
        context.$msg.error(tx.rawLog)
        return
      }
      context.isDebounce = false;
      context.$sleep(2000)
    }

    context.$hideLoading();
    context.$msg.error(context.$t("global.Contracttimeout"))
}catch(err){
  context.$hideLoading();
  console.log(err, 'error===>')
  console.log("错误信息:", err.message);
  dealErr(err,context)

  }
}

// f-token claim_all_unstake
export const fTokenClaimAllUnstake = async (account,configStake) => {
  let client =  await  CosmClient()
  let records = await client.queryContractSmart(
    configStake,
    {
      "user_unbond_amount": {"address": account},
    }
  );
  console.log(records,'unstake_records')
  const unbondAmount = records.unbond_amount.unbond_amount
  return unbondAmount
}

// f-token\infi user_rewarded_amount
export const UserInfiRewarded = async (account,configStake) => {
  let client =  await  CosmClient()
  let records = await client.queryContractSmart(
    configStake,
    {
      "user_rewarded_amount": {"address": account},
    }
  );
  console.log(records,'UserInfiRewarded===ftiav')
  const unbondAmount = records.user_rewarded_amount.amount
  return unbondAmount
}

// infi user_rewarded_amount
export const fTokenInfiUserRewarded = async (account,configStake) => {
  let client =  await  CosmClient()
  let records = await client.queryContractSmart(
    configStake,
    {
      "user_rewarded_amount": {"address": account},
    }
  );
  console.log(records,'unstake_records')
  const unbondAmount = records.user_rewarded_amount.amount
  return unbondAmount
}

// 查询空投数量
export const getClaimAmount = async (account,configStake) => {
  console.log('configStake',configStake)
  let client =  await  CosmClient()
  let records = await client.queryContractSmart(
    configStake,
    {
      "get_claim_amount": {"claimant": account},
    }
  );
  console.log(records,'get_claim_amount')
  const airdropAmount = records.amount
  return airdropAmount
}

//  授权INFI金额
export const approveINFI  = async (context) => {
  context.$showLoading("Loading");

  const client = await CosmClient()
  try {

    let account = await getAccount()
    const sendMsg = {
        "increase_allowance": {
            "amount": "999999999999999999999999",
            "spender": getConfig.juno.infiStake,
        }
    };

    let  result = await client.execute(
        account,
        INFI,
        sendMsg,
        "auto",
        "",
    );
    //console.log(result);
    for (let i = 0; i < 200; i++) {
      const txId = result.transactionHash
      let tx= await client.getTx(txId);
      //console.log("receipt")
      //console.log( tx)

      if (tx && +tx.code == 0) {
        context.$hideLoading();
        context.$msg.success(context.$t("exchange.ApproveSuccess"), 3000)
        return
      } else if (tx && +tx.code != 0) {
        //console.log("receipt" + tx)
        context.$msg.error(tx.rawLog)
        return
      }
      context.isDebounce = false;
      context.$sleep(2000)
    }

    context.$hideLoading();
    context.$msg.error(context.$t("global.Contracttimeout"))

} catch (err) {
  context.$hideLoading();
  console.log(err, 'error===>')
  console.log("错误信息:", err.message);
  dealErr(err,context)

}
}
// 质押INFI

export const stakeInfi = async (context,amount,callback) => {
  context.$showLoading("Loading");
  const client = await CosmClient()
  const amountResult = new Decimal(amount).times(new Decimal('1e6'));
  console.log('Output:质押INFI合约amount',amountResult.toString()); // O
  try{
    let account = await getAccount()
    const sendMsg = {
      "stake": {
          "amount": amountResult,
          "contract": INFI,
          "msg": ""
      }
  };

  let result = await client.execute(
    account,
    getConfig.juno.infiStake,
    sendMsg,
    "auto",
    "",
  );

  for (let i = 0; i < 200; i++) {
    const txId = result.transactionHash
    let tx= await client.getTx(txId);
    //console.log("receipt")
    //console.log( tx)

    if (tx && +tx.code == 0) {
      context.$hideLoading();
      context.$msg.success(context.$t('exchange.stakeSucc'), 5000,{
        type:'link',
        text:context.$t('global.ViewOnBlockchain'),
        link:`https://www.mintscan.io/juno/tx/${tx.hash}`,
        show:true
       })
       callback && callback()
      return
    } else if (tx && +tx.code != 0) {
      //console.log("receipt" + tx)
      context.$msg.error(tx.rawLog)
      return
    }
    context.isDebounce = false;
    context.$sleep(2000)
  }

  context.$hideLoading();
  context.$msg.error(context.$t("global.Contracttimeout"))
  }catch(err){
    context.$hideLoading();
    console.log(err, 'error===>')
    console.log("错误信息:", err.message);
    dealErr(err,context)

  }
}


// 解押infi

export const unstakeInfi = async (context,amount,callback) => {
  console.log('unstakeInfi==990909',getConfig.juno.infiStake)
  console.log(INFI,'INFI')
  context.$showLoading("Loading");

  const client = await CosmClient()
  const amountResult = new Decimal(amount).times(new Decimal('1e6'));
  console.log('Output:解押INFI合约amount',amountResult.toString()); // O
  try{
    let account = await getAccount()
    const sendMsg = {
      "unstake":{
        "amount": amountResult,
        "contract":INFI,
         "msg": ""
      }
  };
//console.log(sendMsg,'sendMsg===>')
  let result = await client.execute(
      account,
      getConfig.juno.infiStake,
      sendMsg,
      "auto",
      "",
  );

  //console.log(result,'stakeFatom===>');
  for (let i = 0; i < 200; i++) {
    const txId = result.transactionHash
    let tx= await client.getTx(txId);
    //console.log("receipt")
    //console.log( tx)

    if (tx && +tx.code == 0) {
      context.$hideLoading();
      context.$msg.success(context.$t("exchange.UnstakeSucc"), 5000,{
        type:'link',
        text:context.$t('global.ViewOnBlockchain'),
        link:`https://www.mintscan.io/juno/tx/${tx.hash}`,
        show:true
       })

      callback && callback()
      return
    } else if (tx && +tx.code != 0) {
      //console.log("receipt" + tx)
      context.$msg.error(tx.rawLog)
      return
    }
    context.isDebounce = false;
    context.$sleep(2000)
  }

  context.$hideLoading();
  context.$msg.error(context.$t("global.Contracttimeout"))

  }catch(err){
    context.$hideLoading();
    console.log(err, 'error===>')
    console.log("错误信息:", err.message);
    dealErr(err,context)

  }
}

// 是否需要发起授权操作
export const getAllowance = async (context,ownerAddress,ownerContract ) => {
  context.$showLoading("Loading");
  let client = await CosmClient()
  try{
  let account = await getAccount()
  const sendMsg = {
    "allowance":{
      "owner":account,
      "spender":ownerContract, //config
    }

  };
  let allowance = await client.queryContractSmart(
    ownerAddress,
    sendMsg,
  );
  return allowance;
}catch(err){
    console.log(err, 'error===>')
    console.log(err, 'error===>')
    console.log("错误信息:", err.message);
    dealErr(err,context)

}

}
// claim
export const unstakeInfiBalance = async  (account) => {
  let client =  await  CosmClient()
  let balance = await client.queryContractSmart(
    getConfig.juno.infiStake,
    {
      "user_unstakable": {"address": account},
    }
  );
  return balance
}

export const userUnstakeRecords = async  (account) => {
  let client =  await  CosmClient()
  let records = await client.queryContractSmart(
    getConfig.juno.infiStake,
    {
      "user_unstake_records": {"address": account},
    }
  );
  console.log(records.unstake_records,'unstake_records')
  const unstakeRecords = records.unstake_records.records
  return unstakeRecords
}
// 解锁期已过但是还未领取的币
export const getUserClaimable = async  (account) => {

  let client =  await CosmClient()
  let result = await client.queryContractSmart(
    getConfig.juno.infiStake,
    {
      "user_unstake_claimable": {"address": account},
    }
  );
  let claimAble = result.user_unstake_claimable.claimable_amount
  console.log(`getUserClaimableINFI:`,result)
  return claimAble
}


export const getUserRewardClaimable = async (context,amount,unstakeId) => {
  context.$showLoading("Loading");
  const client = await CosmClient()
  console.log(amount,'amount===>', getConfig.juno.infiStake)
  try{
    let account = await getAccount()
    const sendMsg = {
      "claim_unstake": {
        "id":unstakeId
      }
    };
    //console.log(sendMsg,'sendMsg===>')
    let result = await client.execute(
        account,
        getConfig.juno.infiStake,
        sendMsg,
        "auto",
        "",
    );

    //console.log(result,'result===>')
    for (let i = 0; i < 200; i++) {
      //console.log(result,'result===>')
      const txId = result.transactionHash
      let tx= await client.getTx(txId);
      //console.log("receipt")
      //console.log( tx)

      if (tx && +tx.code == 0) {
        context.$hideLoading();
        context.$msg.success("stake INFI Success", 5000,{
          type:'link',
          text:context.$t('global.ViewOnBlockchain'),
          link:`https://www.mintscan.io/juno/tx/${tx.hash}`,
          show:true
         })
        setTimeout(() => {
          window.location.reload()
        }, 3000)
        return
      } else if (tx && +tx.code != 0) {
        //console.log("receipt" + tx)
        context.$msg.error(tx.rawLog)
        return
      }
      context.isDebounce = false;
      context.$sleep(2000)
    }

    context.$hideLoading();
    context.$msg.error(context.$t("global.Contracttimeout"))
  }catch(err){
    context.$hideLoading();
    console.log(err, 'error===>')
    console.log(err, 'error===>')
    console.log("错误信息:", err.message);
    dealErr(err,context)

  }
}


export const claim = async (context,amount,callback) => {
  context.$showLoading("Loading");
  const client = await CosmClient()
  try{
    let account = await getAccount()
    const sendMsg = { "claim_all_unstake": {
      // "amount": (Number(amount) * 1e6).toString(),
      // "contract":INFI,
    } };
    //console.log(sendMsg,'result===>sendMsg')
    let result = await client.execute(
      account,
      getConfig.juno.infiStake,
      sendMsg,
      "auto",
      "",
    );

    for (let i = 0; i < 200; i++) {
      //console.log(result,'result===>')
      const txId = result.transactionHash
      let tx= await client.getTx(txId);
      //console.log("receipt")
      //console.log( tx)

      if (tx && +tx.code == 0) {
        context.$hideLoading();
        context.$msg.success(context.$t("exchange.UnstakeINFISucc"), 5000,{
          type:'link',
          text:context.$t('global.ViewOnBlockchain'),
          link:`https://www.mintscan.io/juno/tx/${tx.hash}`,
          show:true
         })

        callback && callback()
        return
      } else if (tx && +tx.code != 0) {
        //console.log("receipt" + tx)
        context.$msg.error(tx.rawLog)
        return
      }
      context.isDebounce = false;
      context.$sleep(2000)
    }

    context.$hideLoading();
    context.$msg.error(context.$t("global.Contracttimeout"))
  }catch(err){
    context.$hideLoading();
    console.log(err, 'error===>')
    console.log(err, 'error===>')
    console.log("错误信息:", err.message);
    dealErr(err,context)

  }
  }

// 空投功能
export const getAirdropClaim = async (context,amount,callback) => {
  context.$showLoading("Loading");
  const client = await CosmClient()
  try{
    let account = await getAccount()
    const sendMsg = { "claim": {
      "amount": (Number(amount) * 1e6).toString(),
    } };
    //console.log(sendMsg,'result===>sendMsg')
    let result = await client.execute(
      account,
      getConfig.juno.airdropAddr,
      sendMsg,
      "auto",
      "",
    );

    for (let i = 0; i < 200; i++) {
      //console.log(result,'result===>')
      const txId = result.transactionHash
      let tx= await client.getTx(txId);
      //console.log("receipt")
      //console.log( tx)

      if (tx && +tx.code == 0) {
        context.$hideLoading();
        context.$msg.success(context.$t("exchange.CollectRewardSucc"), 5000,{
          type:'link',
          text:context.$t('global.ViewOnBlockchain'),
          link:`https://www.mintscan.io/juno/tx/${tx.hash}`,
          show:true
         })
         callback && callback()
        return
      } else if (tx && +tx.code != 0) {
        //console.log("receipt" + tx)
        context.$msg.error(tx.rawLog)
        return
      }
      context.isDebounce = false;
      context.$sleep(2000)
    }

    context.$hideLoading();
    context.$msg.error(context.$t("global.Contracttimeout"))
  }catch(err){
    context.$hideLoading();
    console.log(err, 'error===>')
    console.log(err, 'error===>')
    console.log("错误信息:", err.message);
    dealErr(err,context)

   }
  }



export function formatAddress(val,num=5) {
  if (!val) {
    return '-'
  }
  let length = val.length;
  let start = val.substring(0, num)
  let end = val.substring(length - num, length)
  return `${start}...${end}`
}


export const  isValidJunoAddress = (address) => {
  try {
    const decodedAddress = bech32.decode(address);
    const hrp = decodedAddress.prefix;

    // 在 Juno Network 中，hrp 通常是 'juno'
    return hrp === 'juno';
  } catch (error) {
    return false; // 解码失败说明不是有效的 Bech32 地址
  }
}
export const  isValidCelestiaAddress = (address) => {
  try {
    const decodedAddress = bech32.decode(address);
    const hrp = decodedAddress.prefix;

    // 在 Celestia Network 中，hrp 通常是 'tia'
    return hrp === 'celestia';
  } catch (error) {
    return false; // 解码失败说明不是有效的 Bech32 地址
  }
}




export const debounce = (func, delay) => {
  let timer = null;
  return function(...args) {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}
