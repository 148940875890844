export default {
  global:{
    cancel:'取消',
    next:'下一步',
    confirm:'确认',
    Copy:'复制',
    Rewards:'奖励',
    Password:'密码',
    Close:'关闭',
    No:'否',
    All:'全部',
    Yes:'是',
    Successful:'成功',
    Done:'完成',
    Save:'保存',
    SEND:"发送",
    comingSoon:'敬请期待!',
    DAY:'天',
    HOUR:'时',
    MIN:'分',
    SEC:'秒',
    Residue:'剩余',
    BUY:'购买',
    BUYED:'不可购买',
    PlatinumMember:'白金卡',
    GlobalPartner:'全球合作伙伴',
    Civilian:'平民',
    copySucc:'复制成功!',
    copyFailed:'Copy Failed!',
    noRecords:'暂无记录',
    useDappExplorer:'请使用PC版Chrome安装Keplr插件，或通过Keplr App打开Dapp浏览器访问',
    changeAccount:'您已将账户切换至',
    referrals:"人",
    prevPage:"上一页",
    nextPage:"下一页",
    total1:"共",
    total2:"条",
    soldOut:"售罄",
    MAX:'最大',
    placeholderInit:'请输入金额',
    Authorize:'授权',
    APR:'收益:',
    Release:'释放',
    Pledge:'质押',
    Token:'Token',
    Receive:'接收',
    unStake:'解质押',
    Stake:'质押',
    Withdraw:'收益提取',
    rewardsHistory:'收益提取历史',
    investHistory:'算力购买历史',
    moreStake:'质押',
    submit:'提交',
    Fund:'资金',
    BSC:'BSC',
    Records:"条记录",
    usdtInsufficient:'USDT 余额不足',
    beenSent:'已发送',
    confirmed:'已确认',
    failed:'失败',
    approveTimeout:'授权额度合约超时，请重新发起操作',
    InsufficientApproval:'授权金额不足，请增加授权额度',
    contractTimeout:'合约执行结果查询超时，您可稍后查询操作结果，以免重复投资',
    createTime:'创建时间',
    amount:"金额",
    incomeAmountHistory:'收益提取历史',
    usdtBalance:"USDT余额",
    signTitle:"提示",
    signContent:'需要授权签名来进行安全访问',
    signBtn:'申请签名',
    signLoadingText: '正在执行签名操作，请勿刷新页面',
    claimReward: '领取奖励',
    copyAddress: '复制地址',
    logOut: '退出',
    connectWallet: '连接钱包',
    invite: '邀请',
    inviteFriends: '邀请朋友',
    inviteLink: '邀请链接',
    copyLink: '复制链接',
    inviteFriend: '邀请朋友',
    copy2Invite: '复制下方链接并分享给你的朋友，邀请他们加入Infinimos',
    Binding: '绑定',
    ReferralCode: '推荐码',
    referalPlaceHolder: '输入推荐钱包地址',
    DefaultofficialReferralCode: '默认官方推荐码',
    BindSucc: '成功复制推荐码！',
    BindDuplicate: '你已经绑定过一个推荐码！',
    referralCodeEmpty: '推荐码不能为空！',
    referralCodeFormat: '推荐码格式不正确！',
    referSelf: '你不能邀请自己！',
    claimYourRewards: '领取你的奖励',
    collectNow: '现在领取',
    noClaimableInfi: '没有可领取的INFI',
    shortLoadingMsg: '正在登录，请勿刷新页面',
    loadingMsg: '请在你的钱包上批准交易并签名。请勿刷新或关闭当前页面，直到交易完成。',
    Contracttimeout: '合约启动超时',
    ViewOnBlockchain: '在区块链上查看',
    SignatureSuccessful:"签名验证成功",
    isRegisterUser:'您暂未注册，请尝试退出登录后重新点击“连接钱包”！',
    accumulatedText:'累计网络邀请奖励',
    invitePopText:'下面显示的数量是基于您的邀请的网络质押金额',
    inviteActionText:'网体邀请收益=邀请总收益*0.8（适用于有邀请人，如果没有，乘以1）',
    GoveranceNode:'治理节点',
    LightNode:'轻节点',
    settingsText:'设置',
    Claim:'提取',
    Warning:'警告'
  },
  menu:{
    Exchange:'交易',
    fTokenPool: 'F-TOKEN 池',
    infiPool:'INFI 池',
    airDrop:'空投',
    whitePaper:'白皮书',
    term: '使用条款',
    privacy: '隐私政策',
    disclaimer: '免责声明'

  },
  exchange:{
    EXCHANGE:'交   易',
    Your:'你的',
    unlockedIn:' 将在以下时间解锁',
    Days:'天',
    Hours:'小时',
    Minutes:'分钟',
    Seconds:'秒',
    collectUnlockedAtom:'收集已解锁的ATOM',
    collectUnlockedTia:'收集已解锁的TIA',
    collectUnlockedEth:'收集已解锁的ETH',
    collectUnlockedSol:'收集已解锁的SOL',
    totalUnbonding:'解锁中的金额: ',
    Unlocked:'已解锁的金额: ',
    multipleTimes:'如果你多次从F-token兑换为本地代币，你只会看到下一个解锁周期的金额',
    STAKE:'质押',
    Stake:'质押',
    Balance:'余额',
    receiveLater:'你将在2到3分钟内收到 fTIA。',
    receiveLaterFETH:'你将在2到3分钟内收到 fETH。',
    receiveLaterFSOL:'你将在2到3分钟内收到 fSOL。',
    Unstake:'解质押',
    UNSTAKE:'解质押',
    get:'获取',
    REDEEM:'赎 回',
    Exchange:'交易',
    Redeem:'赎回',
    InsufficientBalance:'余额不足',
    depositMin:'你只能存入最少0.00001 ',
    nextUnlockedAmount:'如果你多次兑换，你只会看到下一个解锁周期的金额',
    ETHInsufficient:'ETH余额不足',
    TIAInsufficient:'TIA余额不足',
    ATOMInsufficient:'ATOM余额不足',
    SOLInsufficient:'SOL余额不足',
    exchangeSucc:'你的交易已成功处理',
    CollectRewardSucc: "你的奖励收集操作已成功处理",
    UnstakeINFISucc: "你的INFI解质押已操作成功处理",
    UnstakeSucc:"你的解质押操作已成功处理",
    stakeSucc:"你的质押操作已成功处理",
    ApproveSuccess:"你的批准操作已成功处理",
    unlockedfund:"你的已解锁资金已成功收集",
    redeemSucc:"你的赎回操作已成功处理",
    modelContent:'请前往您的个人设置绑定ETH/SOL关联地址，以确保代币的准确质押和兑换。',
    modelETHContent:'您连接的ETH钱包地址与绑定地址不一致，请切换钱包后重试.',
    modelSOLContent:'您连接的solana钱包地址与绑定地址不一致，请切换钱包后重试.',
    GoToSetting:'去设置',
    inconsistent:'当前连接地址与绑定的地址不一致!',
    ethTransitionError:'请在PC浏览器进行ETH的交易!',
    solTransitionError:'请在PC浏览器进行SOL的交易!',
    phantomNotInstall:'未检测到solana钱包插件，请先安装Phantom，然后刷新页面重试！',
    solanaConnected:'已连接到Solana：',
    solanaDisconnected:'Solana已断开连接',
    solanaAccountChanged:'您的SOL账号已切换至',
    ethConnected:'已连接到ETH：',
    ethExchangeSuccess:'ETH交易成功',
    ethExchangeFailed:'ETH交易失败',
    transactionTimeout:'交易超时，请关注余额变化，避免重复交易！',
    solanaResult:'SOL 交易结果:',
    atomInfufficient:'ATOM余额不足',
    noAccountConnected:'未获取到已授权的ETH账号',
    Solexchange:'Sol 兑换:',
    confirmedText:'成功',
    finalizedText:'失败'
  },
  ftoken:{
    FTokenPool:'F-Token 池',
    MyPosition:'我的质押仓位',
    POOLTVL:'池子总价值:  ',
    fATOMPOOL:'fATOM 池',
    fDYMPOOL:'fDYM 池',
    fETHPOOL:'fETH 池',
    fSOLPOOL:'fSOL 池',
    Pool:'池',
    HARVEST:'领取',
    STAKEDMOUNT:'质押金额',
    TOTALREWARDS:'总奖励',
    PENDINGREWARD:'待领取奖励',
    fTIAPOOL:'fTIA 池',
    Get:'获取',
    AboutfATOMonJuno:'关于F-token池',
    RewardAPY:'奖励年化收益率',
    ProtocolFee:'协议费',
    interestCycle:'利息周期',
    RatioofFtoken:'F-token 比率',
    InvitationReward:'邀请奖励',
    minimum:'你只能存入最少 0.00001 fATOM',
    fTIAminimum:'你只能存入最少 0.00001 fTIA',
    fETHminimum:'你只能存入最少 0.00001 fETH',
    fSOLminimum:'你只能存入最少 0.00001 fSOL',
    wTomMinimum:'你只能存入最少 0.00001 WATOM',
    wTIAMinimum:'你只能存入最少 0.00001 wTIA',
    wETHMinimum:'你只能存入最少 0.00001 wETH',
    wSOLMinimum:'你只能存入最少 0.00001 wSOL',
    calculated:'奖励每24小时计算一次',
    FATOMInsufficient:'FATOM 不足',
    fAtomStake: '你的 fAtom 质押操作已成功处理',
    fTiaStake:'你的 fTIA 质押操作已成功处理',
    fEthStake:'你的 fETH 质押操作已成功处理',
    fSolStake:'你的 fSOL 质押操作已成功处理',
    withdrawalInavailable:'"无可提取奖励"',
    harvestfATOM:'你的 fATOM 领取操作已成功处理',
    harvestfTIA:'你的 fTIA 领取操作已成功处理',
    harvestfETH:'你的 fETH 领取操作已成功处理',
    harvestfSOL:'你的 fSOL 领取操作已成功处理',
    unStakewAtom:'你的 wAtom 解质押操作已成功处理',
    unStakewTIA:'你的 wTIA 解质押操作已成功处理',
    unStakewETH:'你的 wETH 解质押操作已成功处理',
    unStakewSOL:'你的 wSOL 解质押操作已成功处理',
    wTIAInsufficient:'wTIA 不足',
    fTIAInsufficient: "fTIA 不足",
    fETHInsufficient: "fETH 不足",
    fSOLInsufficient: "fSOL 不足",
    wETHInsufficient:'wETH 不足',
    wSOLInsufficient:'wSOL 不足',
  },
  infi:{
    InfiPool:'Infi 池',
    TVL:'总价值',
    YourPosition:'我的质押仓位',
    STAKEDINFI:'质押 INFI',
    INFIonJuno:'关于INFI池',
    hours:'小时',
    UnlockPrinciple:'INFI本金解锁周期',
    DAYS:'天',
    minimumInfi:'你只能存入最少 0.00001 INFI',
    INFimultiple:'如果你多次解除对 INFI 的质押，你只会看到下一个解锁周期的 INFI 代币金额',
    harvestSucc:'你的领取操作已成功处理',
    INFIInsufficient:'INFI 不足',
    infiActionText:'总奖励=个人质押收益 + 邀请收益 - 上级贡献-协议费用'
  },
  airdrop:{

    IncentiveAcitivitiy:'激励活动',
    s1:'我们很高兴的宣布 ',
    s2:' 第一阶段的激励计划',
    s3:' 于 ',
    s4:' 2024年1月27日启动， ',
    s5:' 用户将会分享',
    s6:' 100万',
    s7:' 的INFI空投奖励。获得空投条件：质押F-Token ,Infi Token, 邀请朋友。',
    ClaimYourAirdropReward:'领取你的空投奖励',
    CollectRewardToken:'收集奖励代币',
    Airdrophistory:'您的空投',
    Activity:'活动',
    ReleasedDate:'发布日期',
    Eligibility:'资格',
    Amount:'金额',
    airdropHistory:'查看空投历史',
    YourJunoAddress:'您的Juno地址',
    provideAddress:'请提供您的juno地址，以便稍后领取空投',
    ClaimBetween:'提取之间',
    Airdrop:'空投',
    Dashboard:'仪表板',
    airdropToken:'如果空投代币来自以太坊或索拉纳生态系统，它将被空投到您的绑定地址。',
    ScoreLeaderboard:'得分排行榜',
    PastAirdrops:'空投历史',
    RewardsYouCanGet:'您可以获得的奖励',
    InputHere:'请输入'
  },
  settings:{
    ERC20Address:'请提供您的ERC20地址',
    ConfirmMapping:'确认关联',
    ConfirmMappingEd:'已绑定',
    SOLANAAddress:'请提供您的solana地址',
    BEP20Address:'请提供您的BEP20地址',
    firstTooltips:'一旦您确认了关联，您的地址就无法更改！',
    BackToEdit:'返回编辑',
    addressErrMsg:'地址格式不正确！',
    ERC20TextERR:"该erc20地址已被其他用户绑定",
    solTextERR:"该solana地址已被其他用户绑定",
    bepTextERR:"该bep20地址已被其他用户绑定",
    settingsETHSuccess:'设置ERC20地址成功',
    settingsSOLSuccess:'设置Solana地址成功',
    settingsBEPSuccess:'设置BEP20地址成功'
  },
  p:{
    p1: "接受：通过使用Infinimos网站或应用程序，用户同意遵守这些使用条款中概述的条款和条件。",
    p2: "资格：用户必须年满18岁或以上，并且不得是任何使用Infinimos提供的服务将违反任何适用法律或法规的司法管辖区的居民。",
    p3: "账户注册和安全：用户在创建账户时必须提供准确和完整的信息，并负责维护其账户凭据的机密性。",
    p4: "用户行为：用户应负责和道德地行事，禁止的活动包括使用辱骂性语言、骚扰或参与任何非法活动。",
    p5: "知识产权：Infinimos保留与网站或应用程序相关的所有知识产权，并且用户未经Infinimos书面同意前，不得复制、分发、修改或以其他方式使用任何知识产权。",
    p6: "终止：Infinimos保留随时出于任何原因终止用户访问网站或应用程序的权利，包括违反这些使用条款，无需事先通知。",
    p7: "条款修改：Infinimos保留随时更新或修改这些使用条款的权利，无论是否通知用户。您继续使用Infinimos网站或应用程序即表示您接受这些使用条款的更改。",
  },

  p1:{
    p1: "收集的个人数据：收集用户信息，例如钱包地址、IP地址、Telegram资料和邮寄地址（如果选择加入）用于促销材料。",
    p2: "数据收集目的：确保Infinimos的正常运行，提供服务和支持，遵守法律义务，分析趋势和使用情况。",
    p3: "收集方法：通过设备和使用信息、cookies（如果使用）以及用户提供的信息（例如钱包地址、电子邮件或Telegram信息）收集个人数据。",
    p4: "个人数据使用：利用收集的数据进行交易处理、客户支持、市场营销（如果选择加入）以及检测和预防安全事件。",
    p5: "数据保护措施：在将个人数据转移到其他司法管辖区时确保足够的保护水平，并且仅在必要时与服务提供商或特定情况下共享个人数据。",
    p6: "数据保留：不保留客户账户或存储个人身份数据，除了某些由供应商存储的IP地址。",
    p7: "与第三方共享数据：在必要时与供应商、服务提供商、顾问和企业附属公司共享个人数据，并出于法律或合规目的披露信息。",

  },
  p2:{
    p1: "您有责任对通过您访问Infinimos协议和/或智能合约的各种用户界面进行自己的尽职调查，以了解它们呈现的费用和风险。",
    p2: "您必须确保在启动此类交易之前，您的兼容数字资产钱包地址上有足够的适用数字资产余额，以完成在Infinimos协议、智能合约或底层区块链网络上的任何交易。",
    p3: "参与创建Infinimos协议或智能合约的任何个人或实体均不对您的使用、无法使用或与其他用户互动相关的任何索赔或损害负责。",
    p4: "使用Infinimos协议和/或智能合约可能需要您支付费用，例如网络费用和执行交易所需的其他费用。",
    p5: "您使用Infinimos协议和/或智能合约涉及各种风险，包括在提供数字资产时的损失以及由于数字资产价格波动导致的损失。",
  },
  invitePage:{
    YourInviter:'您的邀请人',
    YourInvitee:'您的被邀请人',
    total:'总数',
    address:'地址',
    fAtomStaking:'fAtom 质押',
    fTIAStaking:'fTIA 质押',
    INFIStaking:'INFI 质押',
    date:'激活日期',
    rank:"等级",
    Points:'积分'
  },
  web3:{
    ACTION_REJECTED:"您已取消交易",
    UNPREDICTABLE_GAS_LIMIT:'燃料费不足',
    err32000:'燃料费不足',
    CALL_EXCEPTION:"执行异常，请稍后重试",
    CLAIM_ETH_LIMIT:'每日最多只能提取一次ETH',
    GAS_Insufficient:'交易燃料费用不足',

  },
  error:{
    ERR_NETWORK:"网络异常!"
  }
}
